import React from 'react';
import { BlogCategory, WebsiteWrapper } from '../../parts';
import PageWrapper from '../../containers/PageWrapper';

const BlogCategoryPage = ({ pageContext, location }) => {
  const { lang, translations } = pageContext;
  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <BlogCategory pageContext={pageContext} />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default BlogCategoryPage;
